import React from 'react'
import facebook from '../images/social/facebook.svg'
import twitter from '../images/social/twitter.svg'
import instagram from '../images/social/instagram.svg'


export default function Footer() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="column is-4 social">
            <a title="facebook" href="https://facebook.com/rhinoathleticsclub">
              <img
                className="fas fa"
                src={facebook}
                alt="Facebook"
                style={{ width: '4em', height: 'auto' }}
              />
            </a>
            <a title="twitter" href="https://twitter.com/rhinos_uganda">
              <img
                className="fas fa"
                src={twitter}
                alt="Twitter"
                style={{ width: '4em', height: 'auto' }}
              />
            </a>
            <a title="twitter" href="https://instagram.com/rhinoathleticsclub">
              <img
                className="fas fa"
                src={instagram}
                alt="Instagram"
                style={{ width: '4em', height: 'auto' }}
              />
            </a>
          </div>
      </footer>
    )
}