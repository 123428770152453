import React, { useRef } from "react"
import { Link } from "gatsby"
import logo540x600 from "../images/logo.jpg"
import Footer from "./Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../utils/fontawesome"

export default function Layout({ children }) {
  const navMenu = useRef(null);

  const openNavMenu = () => {
    navMenu.current.style.display = "block";
  }

  const closeNavMenu = () => {
    navMenu.current.style.display = "none";
  }

  return (
    <div class="layout">
      <header>
        <Link to="/"><img src={logo540x600} class="headerLogo" alt="rhino athletics club logo" /></Link>
        <h1>rhino athletics club</h1>
        <span class="hamburger-menu-container"><FontAwesomeIcon className="hamburger-menu" onClick={openNavMenu} icon={["fas", "bars"]} size="2x" /></span>
        <nav ref={navMenu}>
          <ul>
            <li><Link onClick={closeNavMenu} to="/">home</Link></li>
            <li><Link onClick={closeNavMenu} to="/about">about</Link></li>
            <li><Link onClick={closeNavMenu} to="/contact">contact</Link></li>
          </ul>
          <span role="button" class="closeButton" onClick={closeNavMenu}><FontAwesomeIcon icon={["fas", "times"]} /></span>
        </nav>
      </header>
      <main>
      {children}
      </main>
      <Footer />
    </div>
  )
}